"use strict";
exports.__esModule = true;
var tslib_1 = require("tslib");
var Utils = tslib_1.__importStar(require("../util/utils"));
var ErrorInfo = /** @class */ (function () {
    function ErrorInfo(message, code, statusCode, cause) {
        this.message = message;
        this.code = code;
        this.statusCode = statusCode;
        this.cause = cause;
    }
    ErrorInfo.prototype.toString = function () {
        var result = '[' + this.constructor.name;
        if (this.message)
            result += ': ' + this.message;
        if (this.statusCode)
            result += '; statusCode=' + this.statusCode;
        if (this.code)
            result += '; code=' + this.code;
        if (this.cause)
            result += '; cause=' + Utils.inspectError(this.cause);
        if (this.href && !(this.message && this.message.indexOf('help.ably.io') > -1))
            result += '; see ' + this.href + ' ';
        result += ']';
        return result;
    };
    ErrorInfo.fromValues = function (values) {
        var _a = values, message = _a.message, code = _a.code, statusCode = _a.statusCode;
        if (typeof message !== 'string' || typeof code !== 'number' || typeof statusCode !== 'number') {
            throw new Error('ErrorInfo.fromValues(): invalid values: ' + Utils.inspect(values));
        }
        var result = Object.assign(new ErrorInfo(message, code, statusCode), values);
        if (result.code && !result.href) {
            result.href = 'https://help.ably.io/error/' + result.code;
        }
        return result;
    };
    return ErrorInfo;
}());
exports["default"] = ErrorInfo;
