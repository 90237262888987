"use strict";
exports.__esModule = true;
var HttpMethods;
(function (HttpMethods) {
    HttpMethods["Get"] = "get";
    HttpMethods["Delete"] = "delete";
    HttpMethods["Post"] = "post";
    HttpMethods["Put"] = "put";
    HttpMethods["Patch"] = "patch";
})(HttpMethods || (HttpMethods = {}));
exports["default"] = HttpMethods;
