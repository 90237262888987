"use strict";
exports.__esModule = true;
var ChannelStateChange = /** @class */ (function () {
    function ChannelStateChange(previous, current, resumed, reason) {
        this.previous = previous;
        this.current = current;
        if (current === 'attached')
            this.resumed = resumed;
        if (reason)
            this.reason = reason;
    }
    return ChannelStateChange;
}());
exports["default"] = ChannelStateChange;
