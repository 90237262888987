"use strict";
exports.__esModule = true;
var XHRStates;
(function (XHRStates) {
    XHRStates[XHRStates["REQ_SEND"] = 0] = "REQ_SEND";
    XHRStates[XHRStates["REQ_RECV"] = 1] = "REQ_RECV";
    XHRStates[XHRStates["REQ_RECV_POLL"] = 2] = "REQ_RECV_POLL";
    XHRStates[XHRStates["REQ_RECV_STREAM"] = 3] = "REQ_RECV_STREAM";
})(XHRStates || (XHRStates = {}));
exports["default"] = XHRStates;
