"use strict";
exports.__esModule = true;
function inspect(buffer) {
    if (buffer === undefined)
        return 'undefined';
    var view;
    var type;
    if (buffer instanceof ArrayBuffer) {
        type = 'ArrayBuffer';
        view = new DataView(buffer);
    }
    else if (buffer instanceof DataView) {
        type = 'DataView';
        view = buffer;
    }
    if (!view)
        return JSON.stringify(buffer);
    var bytes = [];
    for (var i = 0; i < buffer.byteLength; i++) {
        if (i > 20) {
            bytes.push('...');
            break;
        }
        var byte_ = view.getUint8(i).toString(16);
        if (byte_.length === 1)
            byte_ = '0' + byte_;
        bytes.push(byte_);
    }
    return '<' + type + ' ' + bytes.join(' ') + '>';
}
// Encode string as utf8 into dataview at offset
function utf8Write(view, offset, string) {
    for (var i = 0, l = string.length; i < l; i++) {
        var codePoint = string.charCodeAt(i);
        // One byte of UTF-8
        if (codePoint < 0x80) {
            view.setUint8(offset++, ((codePoint >>> 0) & 0x7f) | 0x00);
            continue;
        }
        // Two bytes of UTF-8
        if (codePoint < 0x800) {
            view.setUint8(offset++, ((codePoint >>> 6) & 0x1f) | 0xc0);
            view.setUint8(offset++, ((codePoint >>> 0) & 0x3f) | 0x80);
            continue;
        }
        // Three bytes of UTF-8.
        if (codePoint < 0x10000) {
            view.setUint8(offset++, ((codePoint >>> 12) & 0x0f) | 0xe0);
            view.setUint8(offset++, ((codePoint >>> 6) & 0x3f) | 0x80);
            view.setUint8(offset++, ((codePoint >>> 0) & 0x3f) | 0x80);
            continue;
        }
        // Four bytes of UTF-8
        if (codePoint < 0x110000) {
            view.setUint8(offset++, ((codePoint >>> 18) & 0x07) | 0xf0);
            view.setUint8(offset++, ((codePoint >>> 12) & 0x3f) | 0x80);
            view.setUint8(offset++, ((codePoint >>> 6) & 0x3f) | 0x80);
            view.setUint8(offset++, ((codePoint >>> 0) & 0x3f) | 0x80);
            continue;
        }
        throw new Error('bad codepoint ' + codePoint);
    }
}
function utf8Read(view, offset, length) {
    var string = '';
    for (var i = offset, end = offset + length; i < end; i++) {
        var byte_ = view.getUint8(i);
        // One byte character
        if ((byte_ & 0x80) === 0x00) {
            string += String.fromCharCode(byte_);
            continue;
        }
        // Two byte character
        if ((byte_ & 0xe0) === 0xc0) {
            string += String.fromCharCode(((byte_ & 0x0f) << 6) | (view.getUint8(++i) & 0x3f));
            continue;
        }
        // Three byte character
        if ((byte_ & 0xf0) === 0xe0) {
            string += String.fromCharCode(((byte_ & 0x0f) << 12) | ((view.getUint8(++i) & 0x3f) << 6) | ((view.getUint8(++i) & 0x3f) << 0));
            continue;
        }
        // Four byte character
        if ((byte_ & 0xf8) === 0xf0) {
            string += String.fromCharCode(((byte_ & 0x07) << 18) |
                ((view.getUint8(++i) & 0x3f) << 12) |
                ((view.getUint8(++i) & 0x3f) << 6) |
                ((view.getUint8(++i) & 0x3f) << 0));
            continue;
        }
        throw new Error('Invalid byte ' + byte_.toString(16));
    }
    return string;
}
function utf8ByteCount(string) {
    var count = 0;
    for (var i = 0, l = string.length; i < l; i++) {
        var codePoint = string.charCodeAt(i);
        if (codePoint < 0x80) {
            count += 1;
            continue;
        }
        if (codePoint < 0x800) {
            count += 2;
            continue;
        }
        if (codePoint < 0x10000) {
            count += 3;
            continue;
        }
        if (codePoint < 0x110000) {
            count += 4;
            continue;
        }
        throw new Error('bad codepoint ' + codePoint);
    }
    return count;
}
function encode(value, sparse) {
    var size = sizeof(value, sparse);
    if (size === 0)
        return undefined;
    var buffer = new ArrayBuffer(size);
    var view = new DataView(buffer);
    _encode(value, view, 0, sparse);
    return buffer;
}
var SH_L_32 = (1 << 16) * (1 << 16), SH_R_32 = 1 / SH_L_32;
function getInt64(view, offset) {
    offset = offset || 0;
    return view.getInt32(offset) * SH_L_32 + view.getUint32(offset + 4);
}
function getUint64(view, offset) {
    offset = offset || 0;
    return view.getUint32(offset) * SH_L_32 + view.getUint32(offset + 4);
}
function setInt64(view, offset, val) {
    if (val < 0x8000000000000000) {
        view.setInt32(offset, Math.floor(val * SH_R_32));
        view.setInt32(offset + 4, val & -1);
    }
    else {
        view.setUint32(offset, 0x7fffffff);
        view.setUint32(offset + 4, 0x7fffffff);
    }
}
function setUint64(view, offset, val) {
    if (val < 0x10000000000000000) {
        view.setUint32(offset, Math.floor(val * SH_R_32));
        view.setInt32(offset + 4, val & -1);
    }
    else {
        view.setUint32(offset, 0xffffffff);
        view.setUint32(offset + 4, 0xffffffff);
    }
}
// https://gist.github.com/frsyuki/5432559 - v5 spec
//
// I've used one extension point from `fixext 1` to store `undefined`. On the wire this
// should translate to exactly 0xd40000
//
// +--------+--------+--------+
// |  0xd4  |  0x00  |  0x00  |
// +--------+--------+--------+
//    ^ fixext |        ^ value part unused (fixed to be 0)
//             ^ indicates undefined value
//
var Decoder = /** @class */ (function () {
    function Decoder(view, offset) {
        var _this = this;
        this.map = function (length) {
            var value = {};
            for (var i = 0; i < length; i++) {
                var key = _this.parse();
                value[key] = _this.parse();
            }
            return value;
        };
        this.bin = function (length) {
            var value = new ArrayBuffer(length);
            new Uint8Array(value).set(new Uint8Array(_this.view.buffer, _this.offset, length), 0);
            _this.offset += length;
            return value;
        };
        this.buf = this.bin;
        this.str = function (length) {
            var value = utf8Read(_this.view, _this.offset, length);
            _this.offset += length;
            return value;
        };
        this.array = function (length) {
            var value = new Array(length);
            for (var i = 0; i < length; i++) {
                value[i] = _this.parse();
            }
            return value;
        };
        this.ext = function (length) {
            _this.offset += length;
            return {
                type: _this.view.getInt8(_this.offset),
                data: _this.buf(length),
            };
        };
        this.parse = function () {
            var type = _this.view.getUint8(_this.offset);
            var value, length;
            // Positive FixInt - 0xxxxxxx
            if ((type & 0x80) === 0x00) {
                _this.offset++;
                return type;
            }
            // FixMap - 1000xxxx
            if ((type & 0xf0) === 0x80) {
                length = type & 0x0f;
                _this.offset++;
                return _this.map(length);
            }
            // FixArray - 1001xxxx
            if ((type & 0xf0) === 0x90) {
                length = type & 0x0f;
                _this.offset++;
                return _this.array(length);
            }
            // FixStr - 101xxxxx
            if ((type & 0xe0) === 0xa0) {
                length = type & 0x1f;
                _this.offset++;
                return _this.str(length);
            }
            // Negative FixInt - 111xxxxx
            if ((type & 0xe0) === 0xe0) {
                value = _this.view.getInt8(_this.offset);
                _this.offset++;
                return value;
            }
            switch (type) {
                // nil
                case 0xc0:
                    _this.offset++;
                    return null;
                // 0xc1 never used - use for undefined (NON-STANDARD)
                case 0xc1:
                    _this.offset++;
                    return undefined;
                // false
                case 0xc2:
                    _this.offset++;
                    return false;
                // true
                case 0xc3:
                    _this.offset++;
                    return true;
                // bin 8
                case 0xc4:
                    length = _this.view.getUint8(_this.offset + 1);
                    _this.offset += 2;
                    return _this.bin(length);
                // bin 16
                case 0xc5:
                    length = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return _this.bin(length);
                // bin 32
                case 0xc6:
                    length = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return _this.bin(length);
                // ext 8
                case 0xc7:
                    length = _this.view.getUint8(_this.offset + 1);
                    _this.offset += 2;
                    return _this.ext(length);
                // ext 16
                case 0xc8:
                    length = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return _this.ext(length);
                // ext 32
                case 0xc9:
                    length = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return _this.ext(length);
                // float 32
                case 0xca:
                    value = _this.view.getFloat32(_this.offset + 1);
                    _this.offset += 5;
                    return value;
                // float 64
                case 0xcb:
                    value = _this.view.getFloat64(_this.offset + 1);
                    _this.offset += 9;
                    return value;
                // uint8
                case 0xcc:
                    value = _this.view.getUint8(_this.offset + 1);
                    _this.offset += 2;
                    return value;
                // uint 16
                case 0xcd:
                    value = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return value;
                // uint 32
                case 0xce:
                    value = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return value;
                // uint 64
                case 0xcf:
                    value = getUint64(_this.view, _this.offset + 1);
                    _this.offset += 9;
                    return value;
                // int 8
                case 0xd0:
                    value = _this.view.getInt8(_this.offset + 1);
                    _this.offset += 2;
                    return value;
                // int 16
                case 0xd1:
                    value = _this.view.getInt16(_this.offset + 1);
                    _this.offset += 3;
                    return value;
                // int 32
                case 0xd2:
                    value = _this.view.getInt32(_this.offset + 1);
                    _this.offset += 5;
                    return value;
                // int 64
                case 0xd3:
                    value = getInt64(_this.view, _this.offset + 1);
                    _this.offset += 9;
                    return value;
                // fixext 1
                case 0xd4:
                    length = 1;
                    _this.offset++;
                    return _this.ext(length);
                // fixext 2
                case 0xd5:
                    length = 2;
                    _this.offset++;
                    return _this.ext(length);
                // fixext 4
                case 0xd6:
                    length = 4;
                    _this.offset++;
                    return _this.ext(length);
                // fixext 8
                case 0xd7:
                    length = 8;
                    _this.offset++;
                    return _this.ext(length);
                // fixext 16
                case 0xd8:
                    length = 16;
                    _this.offset++;
                    return _this.ext(length);
                // str8
                case 0xd9:
                    length = _this.view.getUint8(_this.offset + 1);
                    _this.offset += 2;
                    return _this.str(length);
                // str 16
                case 0xda:
                    length = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return _this.str(length);
                // str 32
                case 0xdb:
                    length = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return _this.str(length);
                // array 16
                case 0xdc:
                    length = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return _this.array(length);
                // array 32
                case 0xdd:
                    length = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return _this.array(length);
                // map 16
                case 0xde:
                    length = _this.view.getUint16(_this.offset + 1);
                    _this.offset += 3;
                    return _this.map(length);
                // map 32
                case 0xdf:
                    length = _this.view.getUint32(_this.offset + 1);
                    _this.offset += 5;
                    return _this.map(length);
            }
            throw new Error('Unknown type 0x' + type.toString(16));
        };
        this.offset = offset || 0;
        this.view = view;
    }
    return Decoder;
}());
function decode(buffer) {
    var view = new DataView(buffer);
    var decoder = new Decoder(view);
    var value = decoder.parse();
    if (decoder.offset !== buffer.byteLength)
        throw new Error(buffer.byteLength - decoder.offset + ' trailing bytes');
    return value;
}
function encodeableKeys(value, sparse) {
    return Object.keys(value).filter(function (e) {
        var val = value[e], type = typeof val;
        return (!sparse || (val !== undefined && val !== null)) && ('function' !== type || !!val.toJSON);
    });
}
function _encode(value, view, offset, sparse) {
    var type = typeof value;
    // Strings Bytes
    // There are four string types: fixstr/str8/str16/str32
    if (typeof value === 'string') {
        var length_1 = utf8ByteCount(value);
        // fixstr
        if (length_1 < 0x20) {
            view.setUint8(offset, length_1 | 0xa0);
            utf8Write(view, offset + 1, value);
            return 1 + length_1;
        }
        // str8
        if (length_1 < 0x100) {
            view.setUint8(offset, 0xd9);
            view.setUint8(offset + 1, length_1);
            utf8Write(view, offset + 2, value);
            return 2 + length_1;
        }
        // str16
        if (length_1 < 0x10000) {
            view.setUint8(offset, 0xda);
            view.setUint16(offset + 1, length_1);
            utf8Write(view, offset + 3, value);
            return 3 + length_1;
        }
        // str32
        if (length_1 < 0x100000000) {
            view.setUint8(offset, 0xdb);
            view.setUint32(offset + 1, length_1);
            utf8Write(view, offset + 5, value);
            return 5 + length_1;
        }
    }
    if (ArrayBuffer.isView && ArrayBuffer.isView(value)) {
        // extract the arraybuffer and fallthrough
        value = value.buffer;
    }
    // There are three bin types: bin8/bin16/bin32
    if (value instanceof ArrayBuffer) {
        var length_2 = value.byteLength;
        // bin8
        if (length_2 < 0x100) {
            view.setUint8(offset, 0xc4);
            view.setUint8(offset + 1, length_2);
            new Uint8Array(view.buffer).set(new Uint8Array(value), offset + 2);
            return 2 + length_2;
        }
        // bin16
        if (length_2 < 0x10000) {
            view.setUint8(offset, 0xc5);
            view.setUint16(offset + 1, length_2);
            new Uint8Array(view.buffer).set(new Uint8Array(value), offset + 3);
            return 3 + length_2;
        }
        // bin 32
        if (length_2 < 0x100000000) {
            view.setUint8(offset, 0xc6);
            view.setUint32(offset + 1, length_2);
            new Uint8Array(view.buffer).set(new Uint8Array(value), offset + 5);
            return 5 + length_2;
        }
    }
    if (typeof value === 'number') {
        // Floating Point
        // NOTE: We're always using float64
        if (Math.floor(value) !== value) {
            view.setUint8(offset, 0xcb);
            view.setFloat64(offset + 1, value);
            return 9;
        }
        // Integers
        if (value >= 0) {
            // positive fixnum
            if (value < 0x80) {
                view.setUint8(offset, value);
                return 1;
            }
            // uint 8
            if (value < 0x100) {
                view.setUint8(offset, 0xcc);
                view.setUint8(offset + 1, value);
                return 2;
            }
            // uint 16
            if (value < 0x10000) {
                view.setUint8(offset, 0xcd);
                view.setUint16(offset + 1, value);
                return 3;
            }
            // uint 32
            if (value < 0x100000000) {
                view.setUint8(offset, 0xce);
                view.setUint32(offset + 1, value);
                return 5;
            }
            // uint 64
            if (value < 0x10000000000000000) {
                view.setUint8(offset, 0xcf);
                setUint64(view, offset + 1, value);
                return 9;
            }
            throw new Error('Number too big 0x' + value.toString(16));
        }
        // negative fixnum
        if (value >= -0x20) {
            view.setInt8(offset, value);
            return 1;
        }
        // int 8
        if (value >= -0x80) {
            view.setUint8(offset, 0xd0);
            view.setInt8(offset + 1, value);
            return 2;
        }
        // int 16
        if (value >= -0x8000) {
            view.setUint8(offset, 0xd1);
            view.setInt16(offset + 1, value);
            return 3;
        }
        // int 32
        if (value >= -0x80000000) {
            view.setUint8(offset, 0xd2);
            view.setInt32(offset + 1, value);
            return 5;
        }
        // int 64
        if (value >= -0x8000000000000000) {
            view.setUint8(offset, 0xd3);
            setInt64(view, offset + 1, value);
            return 9;
        }
        throw new Error('Number too small -0x' + (-value).toString(16).substr(1));
    }
    // undefined - use d4 (NON-STANDARD)
    if (type === 'undefined') {
        if (sparse)
            return 0;
        view.setUint8(offset, 0xd4);
        view.setUint8(offset + 1, 0x00);
        view.setUint8(offset + 2, 0x00);
        return 3;
    }
    // null
    if (value === null) {
        if (sparse)
            return 0;
        view.setUint8(offset, 0xc0);
        return 1;
    }
    // Boolean
    if (type === 'boolean') {
        view.setUint8(offset, value ? 0xc3 : 0xc2);
        return 1;
    }
    if ('function' === typeof value.toJSON)
        return _encode(value.toJSON(), view, offset, sparse);
    // Container Types
    if (type === 'object') {
        var length_3, size = 0;
        var keys = void 0;
        var isArray = Array.isArray(value);
        if (isArray) {
            length_3 = value.length;
        }
        else {
            keys = encodeableKeys(value, sparse);
            length_3 = keys.length;
        }
        if (length_3 < 0x10) {
            view.setUint8(offset, length_3 | (isArray ? 0x90 : 0x80));
            size = 1;
        }
        else if (length_3 < 0x10000) {
            view.setUint8(offset, isArray ? 0xdc : 0xde);
            view.setUint16(offset + 1, length_3);
            size = 3;
        }
        else if (length_3 < 0x100000000) {
            view.setUint8(offset, isArray ? 0xdd : 0xdf);
            view.setUint32(offset + 1, length_3);
            size = 5;
        }
        if (isArray) {
            for (var i = 0; i < length_3; i++) {
                size += _encode(value[i], view, offset + size, sparse);
            }
        }
        else if (keys) {
            for (var i = 0; i < length_3; i++) {
                var key = keys[i];
                size += _encode(key, view, offset + size);
                size += _encode(value[key], view, offset + size, sparse);
            }
        }
        return size;
    }
    if (type === 'function')
        return 0;
    throw new Error('Unknown type ' + type);
}
function sizeof(value, sparse) {
    var type = typeof value;
    // fixstr or str8 or str16 or str32
    if (type === 'string') {
        var length_4 = utf8ByteCount(value);
        if (length_4 < 0x20) {
            return 1 + length_4;
        }
        if (length_4 < 0x100) {
            return 2 + length_4;
        }
        if (length_4 < 0x10000) {
            return 3 + length_4;
        }
        if (length_4 < 0x100000000) {
            return 5 + length_4;
        }
    }
    if (ArrayBuffer.isView && ArrayBuffer.isView(value)) {
        // extract the arraybuffer and fallthrough
        value = value.buffer;
    }
    // bin8 or bin16 or bin32
    if (value instanceof ArrayBuffer) {
        var length_5 = value.byteLength;
        if (length_5 < 0x100) {
            return 2 + length_5;
        }
        if (length_5 < 0x10000) {
            return 3 + length_5;
        }
        if (length_5 < 0x100000000) {
            return 5 + length_5;
        }
    }
    if (typeof value === 'number') {
        // Floating Point (32 bits)
        // double
        if (Math.floor(value) !== value)
            return 9;
        // Integers
        if (value >= 0) {
            // positive fixint
            if (value < 0x80)
                return 1;
            // uint 8
            if (value < 0x100)
                return 2;
            // uint 16
            if (value < 0x10000)
                return 3;
            // uint 32
            if (value < 0x100000000)
                return 5;
            // uint 64
            if (value < 0x10000000000000000)
                return 9;
            // Too big
            throw new Error('Number too big 0x' + value.toString(16));
        }
        // negative fixint
        if (value >= -0x20)
            return 1;
        // int 8
        if (value >= -0x80)
            return 2;
        // int 16
        if (value >= -0x8000)
            return 3;
        // int 32
        if (value >= -0x80000000)
            return 5;
        // int 64
        if (value >= -0x8000000000000000)
            return 9;
        // Too small
        throw new Error('Number too small -0x' + value.toString(16).substr(1));
    }
    // Boolean
    if (type === 'boolean')
        return 1;
    // undefined, null
    if (value === null)
        return sparse ? 0 : 1;
    if (value === undefined)
        return sparse ? 0 : 3;
    if ('function' === typeof value.toJSON)
        return sizeof(value.toJSON(), sparse);
    // Container Types
    if (type === 'object') {
        var length_6, size = 0;
        if (Array.isArray(value)) {
            length_6 = value.length;
            for (var i = 0; i < length_6; i++) {
                size += sizeof(value[i], sparse);
            }
        }
        else {
            var keys = encodeableKeys(value, sparse);
            length_6 = keys.length;
            for (var i = 0; i < length_6; i++) {
                var key = keys[i];
                size += sizeof(key) + sizeof(value[key], sparse);
            }
        }
        if (length_6 < 0x10) {
            return 1 + size;
        }
        if (length_6 < 0x10000) {
            return 3 + size;
        }
        if (length_6 < 0x100000000) {
            return 5 + size;
        }
        throw new Error('Array or object too long 0x' + length_6.toString(16));
    }
    if (type === 'function')
        return 0;
    throw new Error('Unknown type ' + type);
}
exports["default"] = {
    encode: encode,
    decode: decode,
    inspect: inspect,
    utf8Write: utf8Write,
    utf8Read: utf8Read,
    utf8ByteCount: utf8ByteCount,
};
