"use strict";
exports.__esModule = true;
var ConnectionStateChange = /** @class */ (function () {
    function ConnectionStateChange(previous, current, retryIn, reason) {
        this.previous = previous;
        this.current = current;
        if (retryIn)
            this.retryIn = retryIn;
        if (reason)
            this.reason = reason;
    }
    return ConnectionStateChange;
}());
exports["default"] = ConnectionStateChange;
