"use strict";
exports.__esModule = true;
exports.removeSession = exports.getSession = exports.setSession = exports.remove = exports.get = exports.set = void 0;
var tslib_1 = require("tslib");
var Utils = tslib_1.__importStar(require("../../../common/lib/util/utils"));
var sessionSupported;
var localSupported;
var test = 'ablyjs-storage-test';
/* Even just accessing the session/localStorage object can throw a
 * security exception in some circumstances with some browsers. In
 * others, calling setItem will throw. So have to check in this
 * somewhat roundabout way. (If unsupported or no global object,
 * will throw on accessing a property of undefined) */
try {
    global.sessionStorage.setItem(test, test);
    global.sessionStorage.removeItem(test);
    sessionSupported = true;
}
catch (e) {
    sessionSupported = false;
}
try {
    global.localStorage.setItem(test, test);
    global.localStorage.removeItem(test);
    localSupported = true;
}
catch (e) {
    localSupported = false;
}
function storageInterface(session) {
    return session ? global.sessionStorage : global.localStorage;
}
function _set(name, value, ttl, session) {
    var wrappedValue = { value: value };
    if (ttl) {
        wrappedValue.expires = Utils.now() + ttl;
    }
    return storageInterface(session).setItem(name, JSON.stringify(wrappedValue));
}
function _get(name, session) {
    var rawItem = storageInterface(session).getItem(name);
    if (!rawItem)
        return null;
    var wrappedValue = JSON.parse(rawItem);
    if (wrappedValue.expires && wrappedValue.expires < Utils.now()) {
        storageInterface(session).removeItem(name);
        return null;
    }
    return wrappedValue.value;
}
function _remove(name, session) {
    return storageInterface(session).removeItem(name);
}
var set;
exports.set = set;
var get;
exports.get = get;
var remove;
exports.remove = remove;
var setSession;
exports.setSession = setSession;
var getSession;
exports.getSession = getSession;
var removeSession;
exports.removeSession = removeSession;
if (localSupported) {
    exports.set = set = function (name, value, ttl) {
        return _set(name, value, ttl, false);
    };
    exports.get = get = function (name) {
        return _get(name, false);
    };
    exports.remove = remove = function (name) {
        return _remove(name, false);
    };
}
if (sessionSupported) {
    exports.setSession = setSession = function (name, value, ttl) {
        return _set(name, value, ttl, true);
    };
    exports.getSession = getSession = function (name) {
        return _get(name, true);
    };
    exports.removeSession = removeSession = function (name) {
        return _remove(name, true);
    };
}
