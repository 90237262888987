"use strict";
exports.__esModule = true;
var TransportNames;
(function (TransportNames) {
    TransportNames["WebSocket"] = "web_socket";
    TransportNames["Comet"] = "comet";
    TransportNames["XhrStreaming"] = "xhr_streaming";
    TransportNames["XhrPolling"] = "xhr_polling";
    TransportNames["JsonP"] = "jsonp";
})(TransportNames || (TransportNames = {}));
exports["default"] = TransportNames;
