"use strict";
exports.__esModule = true;
var tslib_1 = require("tslib");
var Utils = tslib_1.__importStar(require("../util/utils"));
var MessageCount = /** @class */ (function () {
    function MessageCount(values) {
        this.count = (values && values.count) || 0;
        this.data = (values && values.data) || 0;
        this.uncompressedData = (values && values.uncompressedData) || 0;
        this.failed = (values && values.failed) || 0;
        this.refused = (values && values.refused) || 0;
    }
    return MessageCount;
}());
var MessageCategory = /** @class */ (function (_super) {
    tslib_1.__extends(MessageCategory, _super);
    function MessageCategory(values) {
        var _this = _super.call(this, values) || this;
        if (values && values.category) {
            _this.category = {};
            Utils.forInOwnNonNullProperties(values.category, function (prop) {
                _this.category[prop] = new MessageCount(values.category[prop]);
            });
        }
        return _this;
    }
    return MessageCategory;
}(MessageCount));
var ResourceCount = /** @class */ (function () {
    function ResourceCount(values) {
        this.peak = (values && values.peak) || 0;
        this.min = (values && values.min) || 0;
        this.mean = (values && values.mean) || 0;
        this.opened = (values && values.opened) || 0;
        this.refused = (values && values.refused) || 0;
    }
    return ResourceCount;
}());
var RequestCount = /** @class */ (function () {
    function RequestCount(values) {
        this.succeeded = (values && values.succeeded) || 0;
        this.failed = (values && values.failed) || 0;
        this.refused = (values && values.refused) || 0;
    }
    return RequestCount;
}());
var ConnectionTypes = /** @class */ (function () {
    function ConnectionTypes(values) {
        this.plain = new ResourceCount(values && values.plain);
        this.tls = new ResourceCount(values && values.tls);
        this.all = new ResourceCount(values && values.all);
    }
    return ConnectionTypes;
}());
var MessageTypes = /** @class */ (function () {
    function MessageTypes(values) {
        this.messages = new MessageCategory(values && values.messages);
        this.presence = new MessageCategory(values && values.presence);
        this.all = new MessageCategory(values && values.all);
    }
    return MessageTypes;
}());
var MessageTraffic = /** @class */ (function () {
    function MessageTraffic(values) {
        this.realtime = new MessageTypes(values && values.realtime);
        this.rest = new MessageTypes(values && values.rest);
        this.webhook = new MessageTypes(values && values.webhook);
        this.sharedQueue = new MessageTypes(values && values.sharedQueue);
        this.externalQueue = new MessageTypes(values && values.externalQueue);
        this.httpEvent = new MessageTypes(values && values.httpEvent);
        this.push = new MessageTypes(values && values.push);
        this.all = new MessageTypes(values && values.all);
    }
    return MessageTraffic;
}());
var MessageDirections = /** @class */ (function () {
    function MessageDirections(values) {
        this.all = new MessageTypes(values && values.all);
        this.inbound = new MessageTraffic(values && values.inbound);
        this.outbound = new MessageTraffic(values && values.outbound);
    }
    return MessageDirections;
}());
var XchgMessages = /** @class */ (function () {
    function XchgMessages(values) {
        this.all = new MessageTypes(values && values.all);
        this.producerPaid = new MessageDirections(values && values.producerPaid);
        this.consumerPaid = new MessageDirections(values && values.consumerPaid);
    }
    return XchgMessages;
}());
var PushStats = /** @class */ (function () {
    function PushStats(values) {
        this.messages = (values && values.messages) || 0;
        var notifications = values && values.notifications;
        this.notifications = {
            invalid: (notifications && notifications.invalid) || 0,
            attempted: (notifications && notifications.attempted) || 0,
            successful: (notifications && notifications.successful) || 0,
            failed: (notifications && notifications.failed) || 0,
        };
        this.directPublishes = (values && values.directPublishes) || 0;
    }
    return PushStats;
}());
var ProcessedCount = /** @class */ (function () {
    function ProcessedCount(values) {
        this.succeeded = (values && values.succeeded) || 0;
        this.skipped = (values && values.skipped) || 0;
        this.failed = (values && values.failed) || 0;
    }
    return ProcessedCount;
}());
var ProcessedMessages = /** @class */ (function () {
    function ProcessedMessages(values) {
        var _this = this;
        this.delta = undefined;
        if (values && values.delta) {
            this.delta = {};
            Utils.forInOwnNonNullProperties(values.delta, function (prop) {
                _this.delta[prop] = new ProcessedCount(values.delta[prop]);
            });
        }
    }
    return ProcessedMessages;
}());
var Stats = /** @class */ (function (_super) {
    tslib_1.__extends(Stats, _super);
    function Stats(values) {
        var _this = _super.call(this, values) || this;
        _this.persisted = new MessageTypes(values && values.persisted);
        _this.connections = new ConnectionTypes(values && values.connections);
        _this.channels = new ResourceCount(values && values.channels);
        _this.apiRequests = new RequestCount(values && values.apiRequests);
        _this.tokenRequests = new RequestCount(values && values.tokenRequests);
        _this.xchgProducer = new XchgMessages(values && values.xchgProducer);
        _this.xchgConsumer = new XchgMessages(values && values.xchgConsumer);
        _this.push = new PushStats(values && values.pushStats);
        _this.processed = new ProcessedMessages(values && values.processed);
        _this.inProgress = (values && values.inProgress) || undefined;
        _this.unit = (values && values.unit) || undefined;
        _this.intervalId = (values && values.intervalId) || undefined;
        return _this;
    }
    Stats.fromValues = function (values) {
        return new Stats(values);
    };
    return Stats;
}(MessageDirections));
exports["default"] = Stats;
